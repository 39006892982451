"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dayBadgesMap = exports.dayBadges = exports.conditionalBadgesMap = exports.conditionalBadges = exports.badgeLevelsMap = exports.badgeLevels = void 0;
var _christmas = _interopRequireDefault(require("../assets/images/badges/christmas.svg"));
var _ = _interopRequireDefault(require("../assets/images/badges/contributor/1.svg"));
var _2 = _interopRequireDefault(require("../assets/images/badges/contributor/2.svg"));
var _3 = _interopRequireDefault(require("../assets/images/badges/contributor/3.svg"));
var _4 = _interopRequireDefault(require("../assets/images/badges/contributor/4.svg"));
var _5 = _interopRequireDefault(require("../assets/images/badges/contributor/5.svg"));
var _6 = _interopRequireDefault(require("../assets/images/badges/contributor/6.svg"));
var _7 = _interopRequireDefault(require("../assets/images/badges/distance/1.svg"));
var _8 = _interopRequireDefault(require("../assets/images/badges/distance/2.svg"));
var _9 = _interopRequireDefault(require("../assets/images/badges/distance/3.svg"));
var _10 = _interopRequireDefault(require("../assets/images/badges/distance/4.svg"));
var _11 = _interopRequireDefault(require("../assets/images/badges/distance/5.svg"));
var _12 = _interopRequireDefault(require("../assets/images/badges/distance/6.svg"));
var _endOfTheYear = _interopRequireDefault(require("../assets/images/badges/end-of-the-year.svg"));
var _firstOfTheYear = _interopRequireDefault(require("../assets/images/badges/first-of-the-year.svg"));
var _halloween = _interopRequireDefault(require("../assets/images/badges/halloween.svg"));
var _longDistanceIcon = _interopRequireDefault(require("../assets/images/badges/long-distance-icon.svg"));
var _longDistance = _interopRequireDefault(require("../assets/images/badges/long-distance.svg"));
var _13 = _interopRequireDefault(require("../assets/images/badges/night-bird/1.svg"));
var _14 = _interopRequireDefault(require("../assets/images/badges/night-bird/2.svg"));
var _15 = _interopRequireDefault(require("../assets/images/badges/night-bird/3.svg"));
var _16 = _interopRequireDefault(require("../assets/images/badges/night-bird/4.svg"));
var _17 = _interopRequireDefault(require("../assets/images/badges/night-bird/5.svg"));
var _18 = _interopRequireDefault(require("../assets/images/badges/night-bird/6.svg"));
var _19 = _interopRequireDefault(require("../assets/images/badges/night-bird/7.svg"));
var _nightBirdIcon = _interopRequireDefault(require("../assets/images/badges/night-bird-icon.svg"));
var _nightBird = _interopRequireDefault(require("../assets/images/badges/night-bird.svg"));
var _regularity = _interopRequireDefault(require("../assets/images/badges/regularity.svg"));
var _20 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/1.svg"));
var _21 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/2.svg"));
var _22 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/3.svg"));
var _23 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/4.svg"));
var _24 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/5.svg"));
var _25 = _interopRequireDefault(require("../assets/images/badges/vertical-gain/6.svg"));
var _verticalGainIcon = _interopRequireDefault(require("../assets/images/badges/vertical-gain-icon.svg"));
var _verticalGain = _interopRequireDefault(require("../assets/images/badges/vertical-gain.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const badgeLevels = exports.badgeLevels = [
// 'cyclingDistance',
'cyclingMaxDistance', 'nightBird', 'verticalGain',
// 'regularity',
// 'cyclingMaxDistanceOverOneMonth',
'contributor'];
const badgeLevelsMap = exports.badgeLevelsMap = {
  /* cyclingDistance: {
    bgColor: '#FFECA9',
    color: '#BB8A1A',
    image: distanceBadge,
    labelKey: 'commons.achievements.badge_titles.cycling_distance',
    progressKey: 'commons.achievements.progress.cycling_distance',
  }, */
  /* regularity: {
    bgColor: '#CDF5E3',
    color: '#048760',
    image: regularityBadge,
    labelKey: 'commons.achievements.badge_titles.regularity',
    progressKey: 'commons.achievements.progress.regularity',
  }, */
  contributor: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    images: [_.default, _2.default, _3.default, _4.default, _5.default, _6.default],
    labelKey: 'commons.achievements.badge_titles.contributor',
    progressKey: 'commons.achievements.progress.contributor'
  },
  cyclingMaxDistance: {
    bgColor: '#FFECA9',
    color: '#BB8A1A',
    images: [_7.default, _8.default, _9.default, _10.default, _11.default, _12.default],
    labelKey: 'commons.achievements.badge_titles.cycling_max_distance',
    progressKey: 'commons.achievements.progress.cycling_max_distance'
  },
  nightBird: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    images: [_13.default, _14.default, _15.default, _16.default, _17.default, _18.default, _19.default],
    labelKey: 'commons.achievements.badge_titles.night_bird',
    progressKey: 'commons.achievements.progress.night_bird'
  },
  verticalGain: {
    bgColor: '#C9E9F8',
    color: '#006692',
    images: [_20.default, _21.default, _22.default, _23.default, _24.default, _25.default],
    labelKey: 'commons.achievements.badge_titles.vertical_gain',
    progressKey: 'commons.achievements.progress.vertical_gain'
  },
  cyclingMaxDistanceOverOneMonth: {
    bgColor: '#CDF5E3',
    color: '#048760',
    images: [_regularity.default],
    labelKey: 'commons.achievements.badge_titles.cycling_max_distance_over_one_month',
    progressKey: ''
  }
};
const conditionalBadges = exports.conditionalBadges = ['verticalGain', 'longDistance', 'nightBird'];
const conditionalBadgesMap = exports.conditionalBadgesMap = {
  longDistance: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    image: _longDistance.default,
    icon: _longDistanceIcon.default,
    labelKey: 'commons.achievements.badge_titles.long_distance',
    progressKey: 'commons.achievements.progress.long_distance'
  },
  nightBird: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    image: _nightBird.default,
    icon: _nightBirdIcon.default,
    labelKey: 'commons.achievements.badge_titles.night_bird',
    progressKey: 'commons.achievements.progress.night_bird'
  },
  verticalGain: {
    bgColor: '#C9E9F8',
    color: '#006692',
    image: _verticalGain.default,
    icon: _verticalGainIcon.default,
    labelKey: 'commons.achievements.badge_titles.vertical_gain',
    progressKey: 'commons.achievements.progress.vertical_gain'
  }
};
const dayBadges = exports.dayBadges = ['HALLOWEEN', 'CHRISTMAS_DAY', 'NEW_YEARS_EVE', 'NEW_YEARS_DAY'];
const dayBadgesMap = exports.dayBadgesMap = {
  HALLOWEEN: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    image: _halloween.default,
    labelKey: 'commons.achievements.badge_titles.halloween',
    descriptionKey: 'commons.achievements.badge_descriptions.halloween'
  },
  CHRISTMAS_DAY: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    image: _christmas.default,
    labelKey: 'commons.achievements.badge_titles.christmas_day',
    descriptionKey: 'commons.achievements.badge_descriptions.christmas_day'
  },
  NEW_YEARS_EVE: {
    bgColor: '#CDF5E3',
    color: '#048760',
    image: _endOfTheYear.default,
    labelKey: 'commons.achievements.badge_titles.new_year_eve',
    descriptionKey: 'commons.achievements.badge_descriptions.new_year_eve'
  },
  NEW_YEARS_DAY: {
    bgColor: '#FFEBF5',
    color: '#C6377C',
    image: _firstOfTheYear.default,
    labelKey: 'commons.achievements.badge_titles.new_year_day',
    descriptionKey: 'commons.achievements.badge_descriptions.new_year_day'
  }
};